import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'

const Err404 = ({ data }) => {
  return (
    <Layout title="404">
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main" style={{ textAlign: 'center' }}>
        {data.markdownRemark.frontmatter.message}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query NotFoundQuery {
  markdownRemark(frontmatter: {title: {eq: "404"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      message
    }
  }
}
`

export default Err404